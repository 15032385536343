import React, { lazy, Suspense } from 'react'
import { PageContentSlot } from '@wf-mfe/layout'
// @ts-expect-error not exported types
import { getObjCodeForTopLevelRoute } from '@wf-mfe/navigation'
import { useLocation, useParams } from 'react-router-dom'
import { ErrorBoundary } from '../utils/logger'
import { getObjectTypeFromPath } from '../utils/helpers'
import { useIsTreatmentEnabled } from '@wf-mfe/toggles'

const PlanningView = lazy(() =>
  import('@wf-mfe-maestro/components').then((module) => ({
    default: module.PlanningView,
  }))
)

const NewPlanningView = lazy(() =>
  import('@wf-mfe-maestro/connections').then((module) => ({
    default: module.PlanningView,
  }))
)

export const PlanningConnectionPage = () => {
  const { ID } = useParams()

  const { pathname } = useLocation()
  const objType = getObjectTypeFromPath(pathname)

  const objCode = getObjCodeForTopLevelRoute(objType)

  const isConnectionsMFEEnabled = useIsTreatmentEnabled(
    'wf-planning-connections-mfe'
  )

  return (
    <PageContentSlot mfeName="@wf-mfe/maestro-wrapper-ui">
      <Suspense fallback={null}>
        <ErrorBoundary>
          {isConnectionsMFEEnabled ? (
            <NewPlanningView
              connectionName="workfront"
              objectName={objCode}
              recordId={ID || ''}
            />
          ) : (
            <PlanningView
              connectionName="workfront"
              objectName={objCode}
              recordId={ID || ''}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </PageContentSlot>
  )
}

export const PlanningConnectionRouter = () => {
  return <PlanningConnectionPage />
}
